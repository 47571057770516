/** @jsx jsx */
import { jsx } from 'theme-ui';
import { graphql } from 'gatsby';
import { useLangKey } from '../utils/lang';
import { useHoverState } from './MoreHoverContext';

const more_intl = { de: 'mehr', fr: 'suite', en: 'more', es: 'más' };

const More = ({ msx }) => {
	const langKey = useLangKey();
    const isHover = useHoverState();
    //const shadow = 
	return (
		<span
			sx={{
				whiteSpace: 'nowrap',
				variant: !isHover ? 'text.info' : 'text.infohover',
                ...msx,
				fontSize:0
			}}
		>
			 {more_intl[langKey]} 
		</span>
	);
};
//&#8668; &#8669;
export default More