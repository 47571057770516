import React, { useContext, useReducer } from 'react';

const StateContext = React.createContext();
const DispatchContext = React.createContext();

const moreReducer = (state,name) => {
	
	return name;
};

export function MoreHoverContextProvider({ children, init }) {
	const [ state, dispatch ] = useReducer(moreReducer, init);
	return (
		<DispatchContext.Provider value={dispatch}>
			<StateContext.Provider value={state}>{children}</StateContext.Provider>
		</DispatchContext.Provider>
	);
}

export const useSetHover = () => {
	const dispatch = useContext(DispatchContext);
	return dispatch;
};

export const useHoverState = () => useContext(StateContext);
